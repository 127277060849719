<template>
  <VContainer
    grid-list-lg
    fill-height
    fluid
    :class="{
      'is-loading': activity.isLoading
    }"
  >
    <VRow
      column
      wrap
      fill-height
    >
      <SaveHeader
        :owner="this"
        :mode="mode"
      />
      <VCol style="flex: 1 1 auto">
        <VCard>
          <VCardTitle>Details</VCardTitle>
          <VCardText>
            <template v-if="options.attendances instanceof Array && options.attendances.length && !form.program_id">
              <Autocomplete
                v-model="form.attendance_id"
                clearable
                item-value="id"
                item-text="title"
                :items="options.attendances"
                :placeholder="`Select ${featureName('Attendance')}`"
              />
            </template>
            <template v-if="!form.attendance_id && !form.program_id">
              <p class="title text-sm-center">
                OR Select a Program and Day
              </p>
            </template>
            <template v-if="!form.attendance_id">
              <Autocomplete
                v-model="form.program_id"
                clearable
                item-value="id"
                item-text="title"
                :items="options.programs"
                :placeholder="`Select ${featureName('Program')}`"
              />
              <Autocomplete
                v-model="form.calendar_entry_id"
                :disabled="!form.program_id"
                :items="programInstances"
                item-value="id"
                item-text="date"
                :placeholder="`Select ${featureName('Program')} Day`"
                @input="updateCalendar"
              />
            </template>
            <p>Were you present?</p>
            <VBtnToggle
              v-model="form.is_present"
              label="Were you present?"
            >
              <VBtn
                :value="true"
                color="primary"
              >
                Yes
              </VBtn>
              <VBtn
                :value="false"
                color="primary"
              >
                No
              </VBtn>
            </VBtnToggle>
          </VCardText>
        </VCard>
        <DynamicInputsSubmit
          v-model="form.metadata"
          :input-name="featureName('Deliverable', 'singularize')"
        />
      </VCol>
    </VRow>
  </VContainer>
</template>

<script>
import SaveHeader from "@/components/Elements/Navigation/SaveHeader";
import DynamicInputsSubmit from "@/components/Elements/Forms/DynamicInputsSubmit";
import FormMixin from "@/mixins/Form";
import MetadataMixin from "@/mixins/Metadata";
import { mapActions } from "vuex";
import {
  required,
  requiredIf,
  minLength,
  between,
} from "vuelidate/lib/validators";
import SavesSubmittedData from "../../mixins/SavesSubmittedData.vue";
export default {
  name: "AttendancesSubmitSave",
  components: {
    DynamicInputsSubmit,
    SaveHeader,
  },
  mixins: [FormMixin, MetadataMixin, SavesSubmittedData],
  data() {
    return {
      modelType: "Attendance",
      options: {
        attendances: [],
        programs: [],
      },
      defaultForm: {
        program_id: null,
        calendar_entry_id: null,
        attendance_id: null,
        is_present: true,
        metadata: [],
      },
    };
  },
  validations: {
    form: {
      program_id: {
        required: requiredIf(function (form) {
          return !form.attendance_id;
        }),
      },
      calendar_entry_id: {
        required: requiredIf(function (form) {
          return !form.attendance_id;
        }),
      },
      attendance_id: {
        required: requiredIf(function (form) {
          return !form.program_id;
        }),
      },
    },
  },
  computed: {
    programInstances() {
      const program = this.options.programs.find(
        (program) => program.id == this.form.program_id
      );
      return program instanceof Object &&
        program.past_calendar_entries instanceof Array
        ? program.past_calendar_entries.map((entry) => {
            entry.date = this.$timezone.formatDateTime(entry.date);
            return entry;
          })
        : [];
    },
  },
  methods: {
    ...mapActions({
      doGetFormConfig: "attendance/getFormConfig",
      doSaveAttendance: "attendance/save",
      doSaveAttendanceSubmitted: "attendanceSubmitted/save",
      doGetAttendanceSubmitted: "attendanceSubmitted/getOne",
    }),
    onGetFormConfig() {
      return this.doGetFormConfig();
    },
    onSave() {
      /**
       * Determine the saver to use
       * Depending on whether we're creating or submitting for an attendance
       */
      const saver = this.form.attendance_id
        ? this.doSaveAttendanceSubmitted
        : this.doSaveAttendance;
      return this.saveFormModel(saver);
    },
    onGet(id) {
      return this.doGetAttendanceSubmitted(id);
    },
    updateCalendar(id) {
      this.$log.debug("Id", id);
      const entry = this.programInstances.find(
        (instance) => instance.id === id
      );
      this.form.calendar_id = entry.calendar_id;
    },
  },
};
</script>