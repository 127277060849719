<template>
  <VContainer
    grid-list-lg
    fill-height
    fluid
  >
    <Submit
      v-if="$isUserStudent"
      model="standalone"
    />
    <Save v-else />
  </VContainer>
</template>

<script>
import Save from "./Save";
import Submit from "./Submit";

export default {
  name: "AttendancesForm",
  components: {
    Save,
    Submit
  }
};
</script>

<style lang="scss">
</style>